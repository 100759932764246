<template>
  <v-app class="main" dark>
    <v-navigation-drawer
      persistent
      v-model="drawer"
      enable-resize-watcher
      fixed
      app
      class="text--white"
    >
      <v-list>
        <v-list-item @click="goTo('home')">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item @click="goTo('store')">
          <v-list-item-action>
            <v-icon>store</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Store</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="gotTo('news')">
          <v-list-item-action>
            <v-icon>new_releases</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>News</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="!authenticated" @click="doSignIn()">
          <v-list-item-action>
            <v-icon>account_circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sign In</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item v-if="authenticated" @click="signOut()">
          <v-list-item-action>
            <v-icon>account_circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="authenticated" @click="goTo('downloads')">
          <v-list-item-action>
            <v-icon>cloud_download</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Downloads</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-badge class="mr-3" color="rgb(121, 0, 196)">
              <span slot="badge">{{cartLength}}</span>
              <v-icon @click="goTo('checkout')">shopping_cart</v-icon>
            </v-badge>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Checkout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="goTo('about')">
          <v-list-item-action>
            <v-icon>info</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="goTo('support')">
          <v-list-item-action>
            <v-icon>info</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Support</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="authenticated && isAdmin"></v-divider>
        <v-list-item v-if="isAdmin" @click="goTo('dashboard')">
          <v-list-item-action>
            <v-icon>dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="isAdmin"></v-divider>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app flat absolute :clipped-left="clipped" class="primary" dark height="125" style="background-color:rgba(0, 0, 0, 0.0) !important;">
      <v-flex lg2>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" large v-if="$vuetify.breakpoint.xsOnly"></v-app-bar-nav-icon>
        <v-toolbar-items v-if="!$vuetify.breakpoint.xsOnly" style="height: 125x !important;">
          <router-link :to="`/home`" class="routerLink">
            <v-btn large text style="width:150px; height:125px;">Home</v-btn>
          </router-link>
          <v-menu open-on-hover top offset-x>
            <template v-slot:activator="{ on }">
              <router-link :to="`/store`" class="routerLink">
                <v-btn large text v-on="on" height="125" style="width:150px; height:125px;">Products</v-btn>
              </router-link>
            </template>
            <v-list class="backgroundDark">
              <v-list-item
                v-for="(item, index) in productLinks"
                :key="index"
                @click="goToProduct(item.link)">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!--<router-link :to="`/news`" class="routerLink"> -->
            <!--<v-btn text>News</v-btn> -->
          <!--</router-link> -->
          <a class="routerLink">
            <v-btn large text v-if="!authenticated" @click="doSignIn()" style="width:150px; height:125px;">Sign In</v-btn>
          </a>
          <a class="routerLink">
            <v-btn large text v-if="authenticated" @click="signOut()" style="width:150px; height:125px;">Sign Out</v-btn>
          </a>
          <router-link :to="`/downloads`" class="routerLink">
            <v-btn large text v-if="authenticated" style="width:150px; height:125px;">Downloads</v-btn>
          </router-link>
          <!--<router-link :to="`/support`" class="routerLink">
            <v-btn text>Support</v-btn>
          </router-link>
          <router-link :to="`/about`" class="routerLink">
            <v-btn text>About</v-btn>
          </router-link> -->
          <router-link :to="`/dashboard`" class="routerLink">
            <v-btn text v-if="isAdmin" style="width:150px; height:125px;">Admin</v-btn>
          </router-link>
        </v-toolbar-items>
      </v-flex>
      <v-flex lg10 class="text-center justify-center">
        <v-spacer></v-spacer>
        <router-link to="/">
          <img src="./assets/logo.png" class="logoImage ma-2 mt-2" alt="Cut Through Recordings">
        </router-link>
        <v-spacer></v-spacer>
      </v-flex>
      <v-flex lg2 class="text-right">
        <v-badge class="mr-3" color="rgb(121, 0, 196)">
          <span slot="badge">{{cartLength}}</span>
          <v-icon @click="goTo('checkout')" :large="!$vuetify.breakpoint.xsOnly">shopping_cart</v-icon>
        </v-badge>
      </v-flex>
    </v-app-bar>
    <v-main class="px-0 background pb-0">
      <div>
        <v-alert :value="true" border="left" color="rgb(121, 0, 196)" v-for="(sale,index) in $store.getters.sales" :key="index" class="pl-5 font-weight-bold" style="font-size:1.5rem;">
          Dont miss our {{sale.name}} sale!  Receive up to {{sale.percentage * 100}}% off on select products!
          Offer ends on {{prettyDate(sale.endDate)}}
        </v-alert>
      </div>
      <v-layout flex wrap class="pa-2 dark background">
        <v-flex xs12 sm12 md12 lg12>
          <transition
            :name="'custom-classes-transition'"
            mode="out-in"
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut">
            <router-view class="main-content" :key="$route.fullPath"/>
          </transition>
        </v-flex>
      </v-layout>
      <v-ctr-footer></v-ctr-footer>
    </v-main>
    <v-footer :fixed="fixed" app>
      <span>&copy; 2019 Cut Through Recordings LLC</span>
    </v-footer>
    <!-- <v-signup-dialog></v-signup-dialog> -->


    <v-dialog  max-width="400" v-model="showLoginConfirmation">
      <v-card class="glassMostlyOpaque">
        <v-card-title class="backgroundDark">
          Sign In Successful!
        </v-card-title>
        <v-card-text>
          Welcome back!
        </v-card-text>
        <v-card-actions>
          <v-btn class="primary" @click="showLoginConfirmation = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog  max-width="400" v-model="showCartErrorDialog">
      <v-card class="glassMostlyOpaque">
        <v-card-title class="backgroundDark">
          Error
        </v-card-title>
        <v-card-text>
          That product is already in your cart.
        </v-card-text>
        <v-card-actions>
          <v-btn class="primary" @click="showCartErrorDialog = false">Ok</v-btn>
          <v-btn class="primary" @click="showCartErrorDialog = false; goTo('checkout')">View Cart</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog  max-width="400" v-model="showDownloadErrorDialog">
      <v-card class="glassMostlyOpaque">
        <v-card-title class="backgroundDark">
          Error
        </v-card-title>
        <v-card-text>
          You already own that product.
        </v-card-text>
        <v-card-actions>
          <v-btn class="primary" @click="showDownloadErrorDialog = false">Ok</v-btn>
          <v-btn class="primary" @click="showDownloadErrorDialog = false; goTo('downloads')">View Downloads</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { Database } from './services/database';
import { Authentication } from './services/authentication';
import { functions, database, auth } from 'firebase/app';
import { GlobalService } from './services/global-service';
// import SignUp from './components/SignUp.vue';
import { Sort } from './models/product';
import { setTimeout } from 'timers';
import CTRFooter from './components/CTRFooter.vue';
import { mapState } from 'vuex';
import { Sale } from './models/sale';

const description = `Cut Through Recordings is a progressive audio plugin creator. We strive to create innovative plugins at a more than affordable price.  Come check out a selection of excellent sounding plugins!`;
export default Vue.extend({
  metaInfo: {
    title: 'Cut Through Recordings - Store',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: description },
      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Cut Through Recordings - Store' },
      { itemprop: 'description', content: description },
      { itemprop: 'image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/assets%2FCTR_Icon2.png?alt=media&token=17d36c9e-1883-465c-933c-51053f8ec888' },
    ],
  },
  data() {
    return {
      drawer: false,
      clipped: false,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Cut Through Recordings',
      authenticated: false,
      username: '',
      showIcon: false,
      isAdmin: false,
      productLinks: [
        { name: 'All Plugins Bundle', link: 'All_Plugins_Bundle' },
        { name: 'Convergence', link: 'Convergence'},
        { name: 'EpicPRESS', link: 'EpicPRESS' },
        { name: 'M4 Multiband Compressor', link: 'M4_Multiband_Compressor' },
        { name: 'Entropy II', link: 'Entropy_II_-_Enhanced_Stereo_Delay' },
        { name: 'Faradelay', link: 'Faradelay' },
        { name: 'POP Sound Shaper', link: 'POP_Sound_Shaper' },
      ],
    };
  },
  computed: {
    saleRunning: {
      get(): boolean {
        const sales: Sale[] = this.$store.getters.sales;
        return sales.filter((sale) => {
          const start: Date = new Date(sale.startDate);
          const end: Date = new Date(sale.endDate);
          const current: Date = new Date();
          return current.getTime() >= start.getTime() && current.getTime() <= end.getTime(); 
        }).length > 0;
      },
    },
    cartLength: {
      get(): number {
        return this.$store.getters.cartLength;
      },
    },
    showLoginConfirmation: {
      get(): boolean {
        return this.$store.getters.showLoginConfirmation;
      },
      set(value: boolean): void {
        this.$store.commit('showLoginConfirmation', value);
      },
    },
    showCartErrorDialog: {
      get(): boolean {
        return this.$store.getters.showCartWarningModal;
      },
      set(value: boolean): void {
        this.$store.commit('showCartWarningModal', value);
      },
    },
    showDownloadErrorDialog: {
      get(): boolean {
        return this.$store.getters.showDownloadsWarningModal;
      },
      set(value: boolean): void {
        this.$store.commit('showDownloadsWarningModal', value);
      },
    },
  },
  methods: {
    prettyDate(dateString: string) {
      var monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
      ];
      const date = new Date(dateString);
      var day = date.getDate();
      var monthIndex = date.getMonth();
      var year = date.getFullYear();
      return `${monthNames[monthIndex]} ${day} ${year}`;
    },
    doSignIn() {
      // this.$store.commit('showSignIn', true);
      this.$router.push({name: 'auth'});
      this.drawer = false;
    },
    signOut() {
      auth().signOut();
      this.drawer = false;
      this.$router.push({ name: 'home' });
    },
    goTo(name: any) {
      this.drawer = false;
      this.$router.push({name});
    },
    goToProduct(name: any) {
      this.drawer = false;
      this.$router.push({name: 'product', params: {name}});
    },
  },
  created() {
    this.$store.dispatch('loadNewsPostsFromFirebase');
    this.$store.dispatch('loadSalesFromFirebase');

    setTimeout((x) => { this.showIcon = true; }, 1000);
    Database.getProducts().then((products) => {
      products = Sort(products);
      this.$store.commit('products', products);
    });

    const vm = this;
    auth().onAuthStateChanged((user) => {
      if (user) {
        this.$store.dispatch('loadCartFromFirebase');
        this.$store.dispatch('loadDownloadsFromFirebase');
        vm.authenticated = true;
        vm.username = user.email;
        database().ref(`users/${auth().currentUser.uid}/role`).once('value', (snapshot: any) => {
          const role = snapshot.val();
          this.isAdmin = role === 'admin';
        });
      } else {
        vm.authenticated = false;
        vm.username = '';
      }
    });
  },
  name: 'App',
  components: {
    // 'v-signup-dialog': SignUp,
    'v-ctr-footer': CTRFooter,
  },
});
</script>

<style>
@import "../node_modules/animate.css/animate.css";

.main-icon {
  max-height: 50%;
  min-height: 50%;
}

@font-face {
  font-family: ikaros;
  src: url("./assets/font/Ikaros-Regular.otf");
}

@font-face {
  font-family: Oestrogen;
  src: url("./assets/font/Oestrogen.otf") format("opentype");
}

@font-face {
  font-family: Pixlashed;
  src: url("./assets/font/pixlashed.otf") format("opentype");
}

.site-title {
  font-family: "Righteous" !important;
}
*:not(i):not(site-title):not(alt-font) {
  font-family: "Lato", sans-serif;
}

.main-content {
}

.main {
  
  /* background-image: url("./assets/background.svg") !important; */
  /* background-color: #f0f0f0 !important;
  background-size: 3840px 2160px !important; */
  /* background: rgb(2,0,36); */
  background: linear-gradient(150deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 33%, rgb(121, 0, 196) 100%) !important; 
}

body {
  background: linear-gradient(150deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 33%, rgb(121, 0, 196) 100%) !important;
  color: #f0f0f0 !important;
}

.alt-font {
  font-family: "Righteous" !important;
}

.content {
  background-color: rgba(0,0,0,0);
  border-radius: 5px;
}

.logoImage {
  max-height: 90% !important;
  max-width: 50% !important;
}

a img:hover {
  -moz-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  -o-transform: translate(0px, -5px);
  -webkit-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

a i:hover {
  -moz-transform: translate(0px, -5px);
  -ms-transform: translate(0px, -5px);
  -o-transform: translate(0px, -5px);
  -webkit-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}

.backgroundLightest {
  background-color: rgb(121, 0, 196) !important;
}

.backgroundNormal {
  background-color:  rgba(9,9,121,1) !important;
}

.backgroundDark {
  background-color: rgba(2,0,36,1) !important;
}

.textLightest {
  color: rgb(157, 0, 255) !important
}

.routerLink {
  text-decoration:none; 
  color:white; 
  height:100%;
}

.padContent {
  margin-top: 10rem;
}

.glass {
  background: rgba( 68, 68, 68, 0.20 ) !important;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ) !important;
  backdrop-filter: blur( 20.0px ) !important;
  -webkit-backdrop-filter: blur( 20.0px ) !important;
  border-radius: 10px !important;
  border: 1px solid rgba( 255, 255, 255, 0.18 ) !important;
}

.glassHalfOpacity {
  background: rgba( 68, 68, 68, 0.60 ) !important;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ) !important;
  backdrop-filter: blur( 20.0px ) !important;
  -webkit-backdrop-filter: blur( 20.0px ) !important;
  border-radius: 10px !important;
  border: 1px solid rgba( 255, 255, 255, 0.18 ) !important;
}

.glassMostlyOpaque {
  background: rgba( 68, 68, 68, 0.90 ) !important;
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ) !important;
  backdrop-filter: blur( 20.0px ) !important;
  -webkit-backdrop-filter: blur( 20.0px ) !important;
  border-radius: 10px !important;
  border: 1px solid rgba( 255, 255, 255, 0.18 ) !important;
}

/* .grecaptcha-badge { visibility: hidden; } */
</style>
