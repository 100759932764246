<template>
  <v-container grid-list-md >
    <v-layout flex wrap>
      <v-flex lg2 sm0></v-flex>
      <v-flex lg8 class="content pa-3">
        <v-layout flex wrap class="text-center">
          <v-flex xs12 lg12>
            <div class="display-2 mt-4 main-header">Audio Plugins With Attitude</div>
            <br>
            <v-divider></v-divider>
          </v-flex>
          <v-layout xs12 flex wrap>
            <v-flex xs0 sm0 md2 lg2></v-flex>
            <v-flex xs12 sm12 md4 lg4 class="pa-4 text-right">
              <v-card class="fill-height text-center glass">
                <v-card-title class="primary">Cross Platform</v-card-title>
                <v-card-text>All of our paid plugins are available on Windows, Mac and Linux in VST2 and Audio Unit. AAX will be available soon!</v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs12
              sm12
              md6
              lg6
              class="pa-4"
              v-bind:class="{ 'text-left': !isMobile, 'text-center': isMobile}"
            >
              <router-link to="/product/EpicPRESS">
                <img src="../assets/products/epicpress.png" class="product-image elevation-3">
              </router-link>
            </v-flex>

            <v-flex
              xs12
              sm12
              md6
              lg6
              class="pa-4"
              v-bind:class="{ 'text-right': !isMobile, 'text-center': isMobile}"
            >
              <router-link to="/product/Convergence">
                <img src="../assets/products/convergence.png" class="product-image elevation-3">
              </router-link>
            </v-flex>
            <v-flex xs12 sm12 md4 lg4 class="pa-4 text-left">
              <v-card class="fill-height text-center  glass">
                <v-card-title class="primary">Affordable</v-card-title>
                <v-card-text>Our primary goal at Cut Through Recordings is to provide great plug-ins that anyone can afford. We believe that no one should have to break the bank to get started with their music career.</v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs0 sm0 md2 lg2></v-flex>

            <v-flex xs0 sm0 md2 lg2></v-flex>
            <v-flex xs12 sm12 md4 lg4 class="pa-4 text-right">
              <v-card class="fill-height text-center  glass">
                <v-card-title class="primary">Fast</v-card-title>
                <v-card-text>Speed is of the utmost concern when writing plug-ins and great care is always taken to ensure you get the most out of your PC so you can do more.</v-card-text>
              </v-card>
            </v-flex>
            <v-flex
              xs12
              sm12
              md6
              lg6
              class="pa-4"
              v-bind:class="{ 'text-left': !isMobile, 'text-center': isMobile}"
            >
              <router-link to="/product/Entropy_II_-_Enhanced_Stereo_Delay">
                <img src="../assets/products/entropy.png" class="product-image elevation-3">
              </router-link>
            </v-flex>

            <v-flex
              xs12
              sm12
              md6
              lg6
              class="pa-4"
              v-bind:class="{ 'text-right': !isMobile, 'text-center': isMobile}"
            >
              <router-link to="/product/M4_Multiband_Compressor">
                <img src="../assets/products/m4.png" class="product-image elevation-3">
              </router-link>
            </v-flex>
            <v-flex xs12 sm12 md4 lg4 class="pa-4 text-left">
              <v-card class="fill-height text-center  glass">
                <v-card-title class="primary">Free & Demos</v-card-title>
                <v-card-text>You shouldn't have to buy blindly. We offer demo versions of all of our paid plug-ins so you can try before you buy. Free plugins are also available through our store. All you have to do is sign up to download them!</v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs0 sm0 md2 lg2></v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
      <v-flex lg2 sm0></v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
const description = `Cut Through Recordings is a progressive audio plugin creator. We strive to create innovative plugins at a more than affordable price.  Come check out a selection of excellent sounding plugins!`;
export default Vue.extend({
  metaInfo: {
    title: 'Cut Through Recordings - Home',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: description },
      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Cut Through Recordings - Home' },
      { itemprop: 'description', content: description },
      { itemprop: 'image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F7618318a73af136f1ca2ca9e6ff69901%2FAllPluginsBundle-April2020.png?alt=media&token=fe27b3ad-7bab-4b64-a576-be3c0ef17872' },
      { itemProp: 'og:title', content: 'Cut Through Recordings - Home'},
      { itemProp: 'og:description',  content: description },
      { itemProp: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/products%2F7618318a73af136f1ca2ca9e6ff69901%2FAllPluginsBundle-April2020.png?alt=media&token=fe27b3ad-7bab-4b64-a576-be3c0ef17872'}
    ],
  },
  data() {

    return {
      isMobile: false,
    };
  },

});
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Courgette");
.main-header {
  /*font-family: "Courgette", cursive !important;*/
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

.main-image {
  width: 50vh;
  height: 50vh;
  border-radius: 5px;
}
.v-card__title {
  color: white !important;
  font-weight: bold;
  font-size: 1.5rem;
}
.product-image {
  max-width: 100%;
  max-height: 23vh;
  border-radius: 3px;
}

/* .bkg-img {
  background-image: url(../assets/stock1.jpg);
  background-size: cover;
} */
</style>


