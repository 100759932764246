<template>
  <v-container grid-list-md text-left>
    <v-layout flex wrap>
      <v-flex lg2 sm0></v-flex>
      <v-flex lg8>
        <v-card class="glass">
          <v-card-title class="backgroundDark" style="color: white; font-size: 2rem;">
            <b>Downloads</b>
          </v-card-title>
          <v-card-text>
            <div v-if="downloads.length === 0" class="ma-5">
              <span class="display-2">Empty</span>
            </div>

            <v-list two-line v-if="downloads.length > 0" style="background-color: rgba(0,0,0,0) !important;">
              <div v-for="product in downloads" :key="product.uid">
                <v-subheader style="font-size: 1rem; font-weight: bold;" v-if="product.name"><h2>{{ product.name }} {{prettyVersion(product)}}</h2></v-subheader>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-avatar>
                    <img :src="product.imageURLs[0].url">
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="description" ><h3>{{extractTextFromHtml(product.full_description).substring(0, 255) + "..."}}</h3></v-list-item-title>
                    <v-list-item-subtitle>
                      <v-btn
                        v-if="product.isLegacy || product.category.toLowerCase() != 'plugin'"
                        :href="product.downloadURL.url"
                        class="primary mt-2"
                      >
                        <v-icon class="mr-3">cloud_download</v-icon>Download
                      </v-btn>
                      <v-download-dialog
                        v-if="!product.isLegacy && product.category.toLowerCase() == 'plugin'"
                        :product="product"
                        :productName="product.name"
                        :isDemo="false"
                        class="mt-2"
                      ></v-download-dialog>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex lg2 sm0></v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { Database } from '../services/database';
import { Product, prettyVersion } from '../models/product';
import { GlobalService } from '../services/global-service';
import { database, auth } from 'firebase/app';
import DownloadDialogVue from './DownloadDialog.vue';
import md5 from 'md5';

const description = `Access downloads for your account.`;

export default Vue.extend({
  metaInfo: {
    title: 'Cut Through Recordings - Downloads',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: description },
      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Cut Through Recordings - Downloads' },
      { itemprop: 'description', content: description },
      { itemprop: 'image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/assets%2FCTR_Icon2.png?alt=media&token=17d36c9e-1883-465c-933c-51053f8ec888' },
    ],
  },
  data() {
    return {
    };
  },
  methods: {
    track() {
      // this.$ga.page('/downloads');
    },
    extractTextFromHtml(s: string) {
      var span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    prettyVersion(product: Product) {
      if (product) {
        return prettyVersion(product);
      } else {
        return '';
      }
    },
  },
  computed: {
    downloads: {
      get(): Product[] {
        console.log(this.$store.getters.downloads);
        return this.$store.getters.downloads;
      },
    },
  },
  created() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        database().ref(`/downloads/${user.uid}`).on('child_changed', (snapshot) => { this.$store.dispatch('loadDownloadsFromFirebase'); });
      }
    });
  },
  components: {
    'v-download-dialog': DownloadDialogVue,
  },
});
</script>

<style>
.description {
  font-size: 0.75rem !important;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.description *{
  font-size: 0.75rem !important;
}
</style>
