<template>
  <v-container grid-list-md text-left>
    <v-layout flex wrap>
      <v-flex lg2 sm0></v-flex>
      <v-flex lg8 class="content  pa-3">
        <h1 class="text-center mb-3">News</h1>
        <div v-for="newsPost in newsPosts" :key="newsPost.uid" class="newsPost pa-3">
          <router-link :to="`/news/${newsPost.uid}`" style="text-decoration:none; color:white;">
            <h2>{{newsPost.title}}</h2>
            <p>{{getFormattedDate(newsPost)}}</p>
            <p v-html="getBriefContent(newsPost.content)"></p>
            <v-btn class="primary">Read</v-btn>
            <hr class="my-4 white">
          </router-link>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { NewsPost } from '../models/newsPost';
export default Vue.extend({
  data() {
    return {

    };
  },
  methods: {
    getFormattedDate(newsPost: NewsPost) {
      if (newsPost.createDate) {
        return new Date(newsPost.createDate).toDateString();
      } else {
        return '';
      }
    },
    openNewsPost(uid: string) {
      this.$router.push(`/news/${uid}`);
    },
    getBriefContent(content) {
      const length = 200;
      // tslint:disable-next-line:max-line-length
      let briefContent = content.replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+(>|$)/g, ' ').substring(0, length - 3);
      briefContent = briefContent.substring(0, briefContent.lastIndexOf(' '));
      briefContent += '...';
      return briefContent;
    },
  },
  computed: {
    newsPosts: {
      get(): NewsPost[] {
        return this.$store.getters.newsPosts;
      },
    },
  },
  created() {
  },
});
</script>

<style scoped>
.newsPost:hover {
  background-color: #223554;
  border-radius: 5px;
  cursor: pointer;
}
</style>

