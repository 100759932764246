<template>
  <v-container grid-list-xs12 text-center class="pa-2">
    <v-layout flex wrap>
      <v-flex lg2 sm0></v-flex>
      <v-flex lg8>
        <v-card class="glass">
          <v-card-title class="backgroundDark" style="color: white; font-size: 2rem;">
            <b>Cart</b>
          </v-card-title>
          <v-card-text>
            <div v-if="products.length == 0" class="ma-5">
              <span class="display-2">Empty</span>
            </div>

            <v-list two-line class="glass">
              <div v-for="(product, index) in products" :key="index">
                <v-subheader><h2>{{ product.name }}</h2></v-subheader>

                <v-divider></v-divider>

                <v-list-item>
                  <v-list-item-avatar>
                    <img :src="getDisplayImage(product).url">
                  </v-list-item-avatar>
                  <v-list-item-action>
                    <v-icon @click="removeFromCart(product)" class="mr-3">remove_circle_outline</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title >{{strip(product.full_description)}}</v-list-item-title>
                    <v-list-item-subtitle style="font-size: 1.25rem;" class="text-left">
                      <span v-if="+product.price > 0 && !onSale(product)">${{product.price}}</span> <!-- If price > 0 and not on sale -->
                      <span v-if="+product.price > 0 && onSale(product)" style="text-decoration: line-through" class="mr-2">${{product.price}}</span> <!-- If price > 0 and not on sale -->
                      <span v-if="+product.price > 0 && onSale(product)" style="color: #ff4d4d">${{salePrice(product)}}</span> <!-- If price > 0 and is on sale -->
                      <span v-if="+product.price === 0 && !onSale(product)">Free</span> <!-- If price is 0 -->
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
            <v-divider></v-divider>
            <div class="title my-3"><h2>Total</h2></div>
            <div class="subheading mb-3"><h2>${{cartTotal}}</h2></div>
            <v-divider class="mb-3"></v-divider>
            <div class="title my-3">Payment Method</div>
            <v-btn class="secondary" @click="checkout()" v-if="cartTotal != 0">
              <v-icon class="mr-2">credit_card</v-icon>Credit / Debit
            </v-btn>
            <PayPal
                    v-if="cartTotal != 0"
                    class="my-2"
                    :amount="cartTotal.toString()"
                    :items="paypalItemsList"
                    currency="USD"
                    :client="paypal.credentials"
                    :button-style="paypal.style"
                    :env="getEnv"
                    v-on:payment-completed="paypalCompleted"
                    @click="paypalCheckoutClicked()"
            ></PayPal>
            <v-btn
              @click="checkoutFreeItems()"
              v-if="cartTotal == 0 && products.length > 0"
            >Free Checkout</v-btn>


          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex lg2 sm0></v-flex>

      <div class="overlay" v-if="processing">
        <div style="position: relative">
          <v-progress-circular indeterminate class="custom-spinner"></v-progress-circular>
        </div>
      </div>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import { PaymentService } from '../services/payment-service';
import { SaleService } from '../services/sale-service';
import PayPal from 'vue-paypal-checkout';
import { Database } from '../services/database';
import { Product } from '../models/product';
import { database, auth } from 'firebase/app';
import { GlobalService } from '../services/global-service';
import firebase from 'firebase/app';

export default Vue.extend({
  data() {
    return {
      stripeKey: 'pk_test_sb2LRZKyFkKHd5Dcxug7SxHy',
      product: {
      },
      paypal: {
        style: {
          label: 'checkout',
          size: 'small',    // small | medium | large | responsive
          shape: 'rect',         // pill | rect
          color: 'blue',         // gold | blue | silver | black
        },
        credentials: {
          sandbox: process.env.VUE_APP_PAYPAL_KEY,
          production: process.env.VUE_APP_PAYPAL_KEY,
        },
      },
      processing: false as boolean,
    };
  },
  computed: {
    getEnv: {
      get() {
        const mode = process.env.NODE_ENV;
        return mode === 'production' ? 'production' : 'sandbox';
      },
    },
    products: {
      get() {
        const products = [];
        this.$store.state.cart.productIds.forEach((productId) => {
          products.push(this.$store.state.products.find((x) => x.uid === productId));
        });
        return products.filter(x => x !== undefined);
      },
    },
    cartTotal: {
      get(): any {
        return this.$store.getters.cartTotal;
      },
    },
    paypalItemsList() {
      const items = [];
      this.products.forEach((product) => {
        if(product.price > 0) {
          items.push({
          name: product.name,
          description: "",
          quantity: "1",
          price: SaleService.SalePrice(product).toString(),
          currency: "USD"
        });
        }
      })
      return items;
    },
  },
  methods: {
    onSale(product: Product) {
      return SaleService.OnSale(product);
    },
    salePrice(product: Product) {
      return SaleService.SalePrice(product);
    },
    track() {
      //this.$ga.page('/');
    },
    checkoutFreeItems() {
      firebase.analytics().logEvent('checkout_free', this.paypalItemsList);
      const currentUser = auth().currentUser;
      if (currentUser !== undefined) {
        database().ref(`/users/${currentUser.uid}/email`).once('value', (snapshot) => {
          const email = snapshot.val();
          if (email) {
            PaymentService.processPayment({ email }, 0, 'free', this.paypalItemsList as [{name: string, amount: string}]).then((x) => {
              this.$store.commit('cart', []);
              this.$router.push({ name: 'downloads' });
            });
          }
        });
      }
    },
    removeFromCart(product: Product) {
      if (auth().currentUser !== undefined) {
        this.$store.dispatch('removeFromCart', product.uid);
      }
    },
    getDisplayImage(product: Product) {
      const key = +Object.keys(product.imageURLs)[0];
      return product.imageURLs[key];
    },
    paypalCompleted(event: any) {
      firebase.analytics().logEvent('paypal_completed', event);
      if (event.state === 'approved') {
        PaymentService.processPayment(event, this.cartTotal * 100, 'paypal', this.paypalItemsList as [{name: string, amount: string}]).then((x) => {
          this.$store.commit('cart', []);
          this.$router.push({ name: 'downloads' });
        });

      }
    },

    paypalCheckoutClicked() {
      firebase.analytics().logEvent('paypalCheckoutOpened');
    },

    checkout() {
      firebase.analytics().logEvent('stripeCheckoutOpened');
      this.$checkout.open({
        name: 'Cut Through Recordings',
        email: 'ethanepr@hotmailcom',
        currency: 'USD',
        amount: this.cartTotal * 100,
        token: (token: any) => {
          PaymentService.processPayment(token, this.cartTotal * 100, 'stripe', this.paypalItemsList as [{name: string, amount: string}]).then((x) => {
            this.$store.commit('cart', []);
            this.$router.push({ name: 'downloads' });
          });
        },
      });
    },
    filterProducts(products: Product[]): Product[] {
      const filtered = new Array<Product>();
      Object.keys(this.products).forEach((key) => {
        const product = this.products[+key];
        if (
          product.name !== '' && product.name !== undefined &&
          product.price !== '' && product.price !== undefined) {
          filtered.push(product);
        }
      });
      return filtered;
    },
    strip(html) {
      return html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, ' ').replace(/&nbsp;/g, ' ');
    },
  },
  created() {
  },
  components: {
    PayPal,
  },
});
</script>

<style scoped>
.thumbnail {
  max-height: 10vh;
}

.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.custom-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
