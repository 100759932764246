<template>
  <v-layout flex wrap class="ma-3">
    <v-flex lg2 xs0 sm0></v-flex>
    <v-flex class="text-center" xs12 sm12 lg8>
      <div class="pa-4 elevation-5 backgroundLightest" style="border-radius: 5px; color: white">
        <h1 class="display-2">About Cut Through Recordings</h1>
        <v-avatar class="my-5 elevation-10" title="Profile" size="175" color="black">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/assets%2FLilyEthanCarlos-2.jpg?alt=media&token=0652ed25-9468-434b-9a9a-5f6108ace2bd"
          >
        </v-avatar>
        <v-divider class="mb-5" dark></v-divider>
        <transition name="mainText" enter-active-class="animated fadeInDown" animations>
          <div key="1" v-show="show">
            <p class="subheading" key="1">
              <h2>
                Our Story
              </h2>
              <br>
              <p>Hello, My name is Ethan Reker and I am the creator of Cut Through Recordings.</p>
              <br>
              <br>
              <p>
              Cut Through Recordings began as a home studio for recording my own band and local artists.  
              The most intriguing part of the production process to me was always the audio plug-ins.  
              It was amazing to see how the host software could be extended with so many unique effects and instruments.
              Entering the audio plug-in marketplace was inevitable for me, so I pursued a degree in Computer Science.  
              </p>
              <br>
              <br>
              <h2>
                Technologies
              </h2>
              <br>
              <p>
              We've released several free plug-ins over the last several years and have tried several plug-in frameworks.  Development really began to take off when we switched to Dplug, the free audio plug-in framework focused on speed and simplicity.
              M4 Multiband Compressor was the first plug-in we released under this new framework and was a big success! Since then we've released 4 other plug-ins using the same tools: POP Sound Shaper, Entropy II, Faradelay, and EpicPRESS. 
              Leveraging the D programming language we've been able to develop several fast and efficient plug-ins.
              </p>
              <br>
              <br>
              <h2>
                Moving Forward
              </h2>
              <p>
                <br>
              The future looks bright for Cut Through Recordings.  Our focus is on creating tools that are one of a kind without breaking the bank.  We want you to be able to have all of our tools at an affordable price so you can do what you do best.
              Cut Through Recordings will continue to stay on the cutting edge of new technologies in the field of Music Production, and always put our customers first!  
              <br>
              <br>If you've made it this far then
              thank you for your time and I hope to do business with you! Also please check out the free plugins we offer like POP Sound Shaper and Entropy.
              </p>
            </p>
          </div>
        </transition>
      </div>
    </v-flex>
    <v-flex lg2 xs0 sm0></v-flex>
  </v-layout>
</template>


<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  metaInfo: {
    title: 'Cut Through Recordings - About',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'About the developer.' },
      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Cut Through Recordings - About' },
      {        itemprop: 'description', content: `I started Cut Through Recordings to give a name to the home recording business that I was attempting to
                  get off the ground several years ago.  I produced all of my band's music and recorded a few local bands.`},
      { itemprop: 'image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/assets%2FCTR_Icon2.png?alt=media&token=17d36c9e-1883-465c-933c-51053f8ec888' },
    ],
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    track() {
      //this.$ga.page('/');
    },
  },
  mounted() {
    setTimeout((x: any) => { this.show = true; }, 500);
  },
});
</script>

<style scoped>
h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
}
</style>

