<template>
  <v-container grid-list-md text-center :class="{'pa-0': $vuetify.breakpoint.smAndDown, 'pa-5': $vuetify.breakpoint.mdAndUp}">
    <v-layout flex wrap v-if="product.uid !== ''">
      <v-flex lg2 sm0 xs0></v-flex>
      <v-flex lg8 sm12 class="content  pa-5">
        <v-layout flex wrap class="mb-3">
          <v-flex xs12 lg8>
            <div v-if="product.imageURLs[0]" @click="showModal = true" style="cursor: pointer">
              <img :src="product.imageURLs[0].url" style="max-width: 100%" class="elevation-5" :alt="product.name">
            </div>
          </v-flex>
          <v-flex xs12 lg4>
            <v-container>
              <v-layout row wrap align-center text-center justify-center class="text-center">
                <span class="product-title">
                  {{product.name}}
                </span>
                <span v-if="product.version" class="text-center">
                  <v-chip color="rgb(121, 0, 196)" text-color="white"><h2>{{currentVersion}}</h2></v-chip>
                </span>
              </v-layout>
            </v-container>
            <v-container text-center>
              <div>
                <span class="title mr-4" style="text-decoration: line-through;" v-if="onSale && +product.price > 0">${{product.price}}</span>
                <span class="title mr-4" v-if="onSale && +product.price > 0" style="color: #ff4d4d">${{price}}</span>
                <span class="title mr-4" v-if="!onSale && +product.price > 0">${{price}}</span>
                <span class="title mr-4" v-if="+price === 0">Free</span>
                <br>
                <v-btn @click="addToCart()" class="backgroundLightest" large rounded>
                  <v-icon class="mr-2">add_shopping_cart</v-icon>Add To Cart
                </v-btn>
                <br>
              </div>
            </v-container>

            <div id="demoSection" v-if="+product.price > 0 && product.category != 'Bundle'">
              <span class="font-weight-bold" v-if="!product.isFreemium">Try the Demo free today!</span>
              <span class="font-weight-bold" v-if="product.isFreemium" >Get it free! (With limited features)</span>
              <br>
              <v-download-dialog
                class="mt-1"
                :product="product"
                :productName="productName"
                isDemo="true"
              ></v-download-dialog>
            </div>
          </v-flex>
        </v-layout>
        <v-layout flex wrap id="icons" class="mt-4">
          <!-- <v-flex xs12 sm12 md6 lg6>
            <v-layout flex class="text-left" align-center>
              <v-flex xs12 sm12 md6 lg6 style="font-size: 1.5rem;">
                <b>Supported OS</b>
              </v-flex>
              <v-flex xs2 v-if="product.windows" class="icon-container">
                <img src="../assets/windows.svg" class="custom-icon" alt="Windows">
              </v-flex>
              <v-flex xs2 v-if="product.mac" class="icon-container">
                <img src="../assets/apple.svg" class="custom-icon" alt="Mac OS">
              </v-flex>
              <v-flex xs2 v-if="product.linux" class="icon-container">
                <img src="../assets/linux.svg" class="custom-icon" alt="linux">
              </v-flex>
            </v-layout>
          </v-flex> -->
          <!-- <v-flex xs12 sm12 md6 lg6>
            <v-layout flex class="text-left" align-center>
              <v-flex xs12 sm12 md3 lg3 style="font-size: 1.5rem;">
                <b>Formats</b>
              </v-flex>
              <v-flex xs2 v-if="product.vst" class="icon-container">
                <img src="../assets/vst.png" class="custom-icon" alt="VST">
              </v-flex>
              <v-flex xs2 v-if="product.au" class="icon-container">
                <img src="../assets/audioUnit.jpg" class="custom-icon" alt="Audio Unit">
              </v-flex>
              <v-flex xs2 v-if="product.aax" class="icon-container">
                <img src="../assets/aax.png" class="custom-icon" alt="AAX">
              </v-flex>
              <v-flex xs2 v-if="product.vst3" class="icon-container">
                <img src="../assets/vst3_logo.svg" class="custom-icon" alt="VST3">
              </v-flex>
              <v-flex xs2 v-if="product.lv2" class="icon-container">
                <img src="../assets/lv2.png" class="custom-icon" alt="LV2">
              </v-flex>
              <v-flex xs2 v-if="product.wav" class="icon-container">
                <img src="../assets/wav.svg" class="custom-icon" alt="Wav File">
              </v-flex>
            </v-layout>
          </v-flex> -->

        </v-layout>
        <v-divider class="mb-3"></v-divider>

        <div v-if="product.docPageUrl">
          <a :href="product.docPageUrl" target="_blank" style="text-decoration: none;">
            <v-btn style="height: 5vh; width:100%;" class="backgroundDark">
              <v-icon class="mr-2 image-link" size="45">list_alt</v-icon>
              {{product.name}} Documentation
            </v-btn>
          </a>
          <!-- <v-divider class="mb-3"></v-divider> -->
        </div>

        <div id="description" class="mb-3 text-left" style="font-size: 1.5rem;" v-html="product.full_description"></div>

        <div v-if="product.videoPreview != '' && product.videoPreview != undefined" v-html="product.videoPreview" style="max-width: 100%;">
          
        </div>

        

        <div id="bundleProductsSection" class="my-5" v-if="product.category === 'Bundle'">
          <v-flex v-for="product in bundledProducts" :key="product.name">
            <v-card class="glass">
              <v-card-title class="backgroundDark">{{product.name}}</v-card-title>
              <v-card-text v-html="product.full_description" class="text-left"></v-card-text>
            </v-card>
          </v-flex>
        </div>

        <v-divider class="mb-5"></v-divider>
        <span class="title" v-if="product.audioURLs && product.category !== 'Bundle'">Audio Samples</span>
        <v-audio-player
          v-for="upload in product.audioURLs"
          :key="upload.name"
          :url="upload.url"
          :name="upload.name"
          class="my-3"
        ></v-audio-player>
      </v-flex>
      <v-flex lg1 sm0></v-flex>
      <v-dialog v-model="showModal" :width="imageWidth">
        <v-carousel v-if="product.imageURLs.length > 0" class="grey darken-4" height="700">
          <v-carousel-item
            v-for="(upload, i) in product.imageURLs"
            :key="i"
            :src="upload.url"
            @click="imageClicked(upload)"
          ></v-carousel-item>
        </v-carousel>
      </v-dialog>
    </v-layout>
    <v-layout flex wrap v-if="product.uid === ''">
      <v-flex lg1 sm0></v-flex>
      <v-flex lg9 class="content  pa-5">
        <span class="title my-5">Please wait... Something awesome is on the way!</span>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-flex>
      <v-flex lg2 sm0></v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Product, prettyVersion } from '../models/product';
import AudioPlayer from './AudioPlayer.vue';
import { database, auth } from 'firebase/app';
import { GlobalService } from '../services/global-service';
import { SaleService } from '../services/sale-service';
import md5 from 'md5';
import DownloadDialogVue from './DownloadDialog.vue';
import { Meta } from '../decorators';
import firebase from 'firebase/app';
import { MetaInfo } from 'vue-meta';
import { productNameToMetaInfo } from '../config/meta'



@Component({
  components: {
    'v-audio-player': AudioPlayer,
    'v-download-dialog': DownloadDialogVue,
  },
})
export default class extends Vue {
  public productName: string = '';
  public loggedIn: boolean = false;
  public showModal: boolean = false;
  public showDownloadModal: boolean = false;
  public imageWidth: number = 1000;
  
  public bundledProducts: Product[] = [];
  public selectedImageIndex = 0;

  @Meta
  public metaInfo() {
    return productNameToMetaInfo(this.productName, this.$route.fullPath);
  }

  @Watch('metaInfo')
  public onMetaInfoChanges() {
    // console.log('meta info changed');
  }

  get plainDescription() {
    return this.product.full_description.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, ' ').replace(/&nbsp;/g, ' ');
  }

  get onSale() {
    return SaleService.OnSale(this.product);
  }

  get salePrice() {
    return SaleService.SalePrice(this.product);
  }

  get product(): Product {
    const p = this.$store.getters.productInView;
    this.bundledProducts = [];
    if (p.category === 'Bundle') {
      p.bundleIds.forEach((bundleId) => {
        database().ref(`/products/${bundleId}`).once('value', (snapshot) => {
          const p2 = snapshot.val();
          p2.imageURLs = GlobalService.ToArray(p2.imageURLs);
          this.bundledProducts.push(p2);
        });
      });
    }
    // this.getImageWidth();
    return p;
  }

  get price(): string {
    return SaleService.SalePrice(this.product);
  }

  @Watch('product')
  onProductChanged(newProduct, oldProduct) {
    this.getImageWidth();
  }

  get currentVersion(): string {
    if (this.product.version) {
      return prettyVersion(this.product);
    } else { return ''; }
  }

  public created() {
    this.bundledProducts = [];
    this.productName = this.$route.params.name.replace(/_/g, ' ');
    // this.metaInfo = productNameToMetaInfo(this.productName);
    const hash = md5(this.productName);
    this.$store.dispatch('loadSingleProductFromFirebase', hash);

    auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }

    });
  }

  public track() {
    // this.$ga.page(`/product/${this.product.name}`);
  }
  public getImageWidth() {
    const img = new Image();
    img.onload = () => {
      this.imageWidth = img.width;
      // console.log(img);
    };
    img.src = this.product.imageURLs[0].url;
  }

  public imageClicked(upload: any) {
    // console.log(upload);s
  }

  public addToCart() {
    if (this.loggedIn && this.product.name !== '') {
      firebase.analytics().logEvent('addToCart', {user: auth().currentUser, product: this.product});
      this.$store.dispatch('addToCart', this.product.uid);
    } else {
      this.$store.commit('showSignIn', true);
    }
  }
}
</script>

<style scoped>

img {
  border-radius: 5px;
}
.v-carousel {
  border-radius: 3px;
}
.custom-icon {
  width: 6vh;
  height: auto;
  border-radius: 0;
}

.flex-container {
  align-items: center; /* Vertical center alignment */
  /*justify-content: center; /* Horizontal center alignment */
}

.product-title {
  font-family: 'ZCOOL XiaoWei', serif !important;
  /* font-family: 'Palanquin', sans-serif; */
  /* font-weight: bold; */
  font-size: 3rem;
}

body {
  color: white !important;
}

.image-link {
  max-height: 5vh;
}
</style>
