<template>
  <v-layout flex wrap>
    <v-flex
      class="pa-2 flexContainer"
      v-for="product in products"
      :key="product.name"
      xs12
      sm6
      md4
      lg4
    >
      <v-card height="100%" class="glass flexcard ma-3 d-flex flex-column">
        <v-card-title>
          <span style="font-size: 2rem;">{{product.name}}</span>
        </v-card-title>
        <div>
          <router-link :to="'/product/' + product.name.replace(/ /g,'_')" class="ma-0 pa-0">
            <img
              style="max-height: 50vh; width: 100%; height:auto;"
              class="pa-0 ma-0 customImg elevation-4"
              v-if="product"
              v-bind:src="getDisplayImage(product)"
            >
          </router-link>
        </div>
        <v-card-text>
          <span style="font-size:1.5rem;">{{getBriefDescription(product)}}</span>
          <br><br>
          <span class="font-weight-bold title" v-if="!onSale(product)">{{getPrice(product)}}</span>
          <span class="font-weight-bold title" v-if="onSale(product)" style="text-decoration: line-through;">{{getPrice(product)}}</span>
          <span class="font-weight-bold title ml-2" v-if="onSale(product)" style="color: #ff4d4d">${{salePrice(product)}}</span>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn @click="addToCart(product)" class="backgroundLightest ml-1 mb-2" rounded large>
            <v-icon>add_shopping_cart</v-icon>
          </v-btn>
          <v-btn @click="openProductPage(product)" class="backgroundDark mb-2" rounded large>Info</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import Vue from 'vue';
import { Product } from '../models/product';
import { auth } from 'firebase/app';
import { SaleService } from '../services/sale-service';
import firebase from 'firebase/app';

// tslint:disable-next-line:max-line-length
const description = 'Products offered by Cut Through Recordings include Multiband Compressors, Stereo Delays, Clippers, sound samples, and much more.  Check out our various free plugins as well.  Available for Windows, MacOS, and Linux!';
export default Vue.extend({
  metaInfo: {
    title: 'Cut Through Recordings - Store',
    meta: [
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: description },
      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Cut Through Recordings - Store' },
      { itemprop: 'description', content: description },
      // tslint:disable-next-line:max-line-length
      { itemprop: 'image', content: 'https://firebasestorage.googleapis.com/v0/b/ctrwebsite-db7d9.appspot.com/o/assets%2FCTR_Icon2.png?alt=media&token=17d36c9e-1883-465c-933c-51053f8ec888' },
    ],
  },
  data() {
    return {
      loggedIn: false,
      cardHeight: 550,
    };
  },
  computed: {
    products(): Product[] {
      return this.$store.state.products.filter(x => !x.discontinued);
    },
  },
  methods: {
    track() {
      //this.$ga.page('/');
    },
    onSale(product: Product) {
      return SaleService.OnSale(product);
    },
    getBriefDescription(product: Product) {
      const length = 200;
      // tslint:disable-next-line:max-line-length
      let briefDescripton = product.full_description.replace(/&nbsp;/g, ' ').replace(/<\/?[^>]+(>|$)/g, ' ').substring(0, length - 3);
      briefDescripton = briefDescripton.substring(0, briefDescripton.lastIndexOf(' '));
      briefDescripton += '...';
      return briefDescripton;
    },
    getPrice(product: Product) {
      if (+product.price === 0) {
        return 'Free';
      } else {
        return '$' + product.price;
      }
    },
    salePrice(product: Product): string {
      return SaleService.SalePrice(product);
    },
    getDisplayImage(product: Product) {
      if (product.imageURLs.length) {
        const key: string = Object.keys(product.imageURLs)[0];
        if (product.imageURLs[key]) {
          return product.imageURLs[key].url;
        }
      }
    },
    openProductPage(product: Product) {
      this.$router.push({ name: 'product', params: { name: product.name.replace(/ /g, '_') } });
    },
    addToCart(product: Product) {
      if (this.loggedIn && product.name !== '') {
        firebase.analytics().logEvent('addToCart', {user: auth().currentUser, product});
        this.$store.dispatch('addToCart', product.uid);
      } else {
        this.$store.commit('showSignIn', true);
      }
    },
    submit() {
      this.$store.commit('showSignIn', false);
    },
  },
  created() {
    auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }

    });
  },
});
</script>

<style scoped>
body {
  font-size: 2rem;
}
img {
  width: 100%;
  max-width: 100%;
}

.flexcard {
  display: flex;
  flex-direction: column;
}

.flexContainer {
  position: relative;
  min-height: 470px;
  margin-bottom: 3vh;
}

.flexContent {
  position: absolute;
  bottom: 0;
  left: 0;
}

.roundedCorners {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}

.customImg {
  /* border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important; */
}
</style>

