<template>
  <div class="pa-5 backgroundLightest">
    <span class="title">Title</span>
    <v-text-field v-model="newsPost.title"></v-text-field>
    <span class="title">Content</span>
    <vue-editor
      v-model="newsPost.content"
      useCustomImageHandler
      @imageAdded="handleImageAdded"
    ></vue-editor>
    <v-btn class="primary" @click="saveButtonClick()">Save Changes</v-btn>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Product } from '../models/product';
import { database, storage } from 'firebase/app';
import md5 from 'md5';
import AudioPlayerVue from './AudioPlayer.vue';
import { Upload } from '../models/product';
import { VueEditor } from 'vue2-editor';
import { StorageService } from '../services/storage';
import { NewsPost } from '@/models/newsPost';

export default Vue.extend({
  data() {
    return {
      newsPost: {} as NewsPost
    };
  },
  props: {},
  methods: {
    track() {
      // this.$ga.page('/edit_news_post');
    },
    saveButtonClick() {
      this.newsPost.uid === '' || this.newsPost.uid === undefined
        ? this.createNewsPost()
        : this.updateNewsPost();
    },
    createNewsPost() {
      console.log('creating new post');
      const hash = md5(this.newsPost.title);
      this.newsPost.uid = hash;
      this.newsPost.createDate = new Date().toUTCString();
      console.log('hash = ' + hash);
      const ref = database().ref(`/news/${hash}`);
      ref.set(this.newsPost);
    },
    updateNewsPost() {
      console.log('updating existing product');
      if (this.newsPost.uid !== '') {
        database()
          .ref(`/news/${this.newsPost.uid}`)
          .set(this.newsPost);
      }
    },
    handleImageAdded(file: any, Editor: any, cursorLocation: any, resetUploader: any) {
      StorageService.upload(file, 'post_images').then((url) => {
        Editor.insertEmbed(cursorLocation, 'image', url);
      });
    },
  },
  created() {
    const uid = this.$route.params.uid;
    if (uid !== undefined) {
      database()
        .ref('/news')
        .on('value', (snapshot) => {
          const news = snapshot.val();
          Object.keys(news).forEach((key) => {
            const n = news[key];
            if (n.uid === uid) {
              this.newsPost = n as NewsPost;
            }
          });
        });
    }
  },
  components: {
    VueEditor,
  },
});
</script>

<style scoped>
img {
  max-height: 30vh;
}
</style>

