@import url(https://fonts.googleapis.com/css?family=Courgette);

.image-link[data-v-50468623] {
  max-height: 5vh;
}


.main-icon {
  max-height: 50%;
  min-height: 50%;
}
@font-face {
  font-family: ikaros;
  src: url(../fonts/Ikaros-Regular.098c7890.otf);
}
@font-face {
  font-family: Oestrogen;
  src: url(../fonts/Oestrogen.c9b16e84.otf) format("opentype");
}
@font-face {
  font-family: Pixlashed;
  src: url(../fonts/pixlashed.9bec6eeb.otf) format("opentype");
}
.site-title {
  font-family: "Righteous" !important;
}
*:not(i):not(site-title):not(alt-font) {
  font-family: "Lato", sans-serif;
}
.main-content {
}
.main {
  
  /* background-image: url("./assets/background.svg") !important; */
  /* background-color: #f0f0f0 !important;
  background-size: 3840px 2160px !important; */
  /* background: rgb(2,0,36); */
  background: linear-gradient(150deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 33%, rgb(121, 0, 196) 100%) !important;
}
body {
  background: linear-gradient(150deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 33%, rgb(121, 0, 196) 100%) !important;
  color: #f0f0f0 !important;
}
.alt-font {
  font-family: "Righteous" !important;
}
.content {
  background-color: rgba(0,0,0,0);
  border-radius: 5px;
}
.logoImage {
  max-height: 90% !important;
  max-width: 50% !important;
}
a img:hover {
  -webkit-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}
a i:hover {
  -webkit-transform: translate(0px, -5px);
  transform: translate(0px, -5px);
}
.backgroundLightest {
  background-color: rgb(121, 0, 196) !important;
}
.backgroundNormal {
  background-color:  rgba(9,9,121,1) !important;
}
.backgroundDark {
  background-color: rgba(2,0,36,1) !important;
}
.textLightest {
  color: rgb(157, 0, 255) !important
}
.routerLink {
  text-decoration:none; 
  color:white; 
  height:100%;
}
.padContent {
  margin-top: 10rem;
}
.glass {
  background: rgba( 68, 68, 68, 0.20 ) !important;
  -webkit-box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ) !important;
          box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ) !important;
  backdrop-filter: blur( 20.0px ) !important;
  -webkit-backdrop-filter: blur( 20.0px ) !important;
  border-radius: 10px !important;
  border: 1px solid rgba( 255, 255, 255, 0.18 ) !important;
}
.glassHalfOpacity {
  background: rgba( 68, 68, 68, 0.60 ) !important;
  -webkit-box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ) !important;
          box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ) !important;
  backdrop-filter: blur( 20.0px ) !important;
  -webkit-backdrop-filter: blur( 20.0px ) !important;
  border-radius: 10px !important;
  border: 1px solid rgba( 255, 255, 255, 0.18 ) !important;
}
.glassMostlyOpaque {
  background: rgba( 68, 68, 68, 0.90 ) !important;
  -webkit-box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ) !important;
          box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ) !important;
  backdrop-filter: blur( 20.0px ) !important;
  -webkit-backdrop-filter: blur( 20.0px ) !important;
  border-radius: 10px !important;
  border: 1px solid rgba( 255, 255, 255, 0.18 ) !important;
}

/* .grecaptcha-badge { visibility: hidden; } */


.main-header[data-v-7cba29a4] {
  /*font-family: "Courgette", cursive !important;*/
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
}
.main-image[data-v-7cba29a4] {
  width: 50vh;
  height: 50vh;
  border-radius: 5px;
}
.v-card__title[data-v-7cba29a4] {
  color: white !important;
  font-weight: bold;
  font-size: 1.5rem;
}
.product-image[data-v-7cba29a4] {
  max-width: 100%;
  max-height: 23vh;
  border-radius: 3px;
}

/* .bkg-img {
  background-image: url(../assets/stock1.jpg);
  background-size: cover;
} */


h1[data-v-56a26aaa] {
  font-size: 3rem;
}
h2[data-v-56a26aaa] {
  font-size: 2rem;
}
p[data-v-56a26aaa] {
  font-size: 1.5rem;
}


body[data-v-1ab38068] {
  font-size: 2rem;
}
img[data-v-1ab38068] {
  width: 100%;
  max-width: 100%;
}
.flexcard[data-v-1ab38068] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.flexContainer[data-v-1ab38068] {
  position: relative;
  min-height: 470px;
  margin-bottom: 3vh;
}
.flexContent[data-v-1ab38068] {
  position: absolute;
  bottom: 0;
  left: 0;
}
.roundedCorners[data-v-1ab38068] {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}
.customImg[data-v-1ab38068] {
  /* border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important; */
}


img[data-v-65d9676f] {
  border-radius: 5px;
}
.v-carousel[data-v-65d9676f] {
  border-radius: 3px;
}
.custom-icon[data-v-65d9676f] {
  width: 6vh;
  height: auto;
  border-radius: 0;
}
.flex-container[data-v-65d9676f] {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; /* Vertical center alignment */
  /*justify-content: center; /* Horizontal center alignment */
}
.product-title[data-v-65d9676f] {
  font-family: 'ZCOOL XiaoWei', serif !important;
  /* font-family: 'Palanquin', sans-serif; */
  /* font-weight: bold; */
  font-size: 3rem;
}
body[data-v-65d9676f] {
  color: white !important;
}
.image-link[data-v-65d9676f] {
  max-height: 5vh;
}


img[data-v-516bad9c] {
  max-height: 30vh;
}


.thumbnail[data-v-409cfb5a] {
  max-height: 10vh;
}
.overlay[data-v-409cfb5a] {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
.custom-spinner[data-v-409cfb5a] {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}


.description {
  font-size: 0.75rem !important;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}
.description *{
  font-size: 0.75rem !important;
}


.image-link[data-v-45c3eeac] {
  max-height: 7vh;
}


.newsPost[data-v-bae8b578]:hover {
  background-color: #223554;
  border-radius: 5px;
  cursor: pointer;
}


img[data-v-fe82f7d6] {
  max-height: 30vh;
}


.button-40p[data-v-3bf96efb] {
  width: 40% !important;
}
.largePadding[data-v-3bf96efb] {
  padding-left: 10rem;
  padding-right: 10rem;
}


/*# sourceMappingURL=app.b62a0daa.css.map*/