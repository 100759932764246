<template>
  <div class="pa-5 backgroundLightest">
    <span class="title">Name</span>
    <v-text-field v-model="product.name"></v-text-field>
    <span class="title">Description</span>
    <vue-editor
      v-model="product.full_description"
      useCustomImageHandler
      @imageAdded="handleImageAdded"
    ></vue-editor>

    <span class="title">Category</span>
    <!-- <v-text-field v-model="product.category"></v-text-field> -->
    <v-radio-group v-model="product.category">
      <v-radio v-for="category in possibleCategories" :label="category" :key="category" :value="category"></v-radio>
    </v-radio-group>

    <div v-if="product.category === 'Bundle'" class="mb-5">
      <v-text-field placeholder="Plugin ID" style="width: 30rem;" v-model="bundleId"></v-text-field>
      <v-btn @click="addToBundleIds()">Add Plugin To Bundle</v-btn>
      <div v-for="bundleId in product.bundleIds" :key="bundleId">
        <span>{{bundleId}}<v-icon @click="removeBundleId(bundleId)">delete</v-icon></span>
      </div>
    </div>

    <span class="title">Price</span>
    <v-text-field v-model="product.price"></v-text-field>

    <div v-if="product.category === 'Plugin'">
      <span class="title">Version</span>
      <v-text-field class="mb-5" v-model="product.version"></v-text-field>
    </div>

    <div>
      <span class="title">Docs Page URL</span>
      <v-text-field class="mb-5" v-model="product.docPageUrl"></v-text-field>
    </div>

    <span class="title">Sort Order</span>
    <v-text-field class="mb-5" v-model="product.order"></v-text-field>

    <div class="my-3">
      <span class="title">Video Preview</span>
      <v-text-field v-model="product.videoPreview"></v-text-field>
    </div>

    <div id="formats">
      <v-checkbox v-model="product.vst" label="VST"></v-checkbox>
      <v-checkbox v-model="product.vst3" label="VST3"></v-checkbox>
      <v-checkbox v-model="product.au" label="Audio Unit"></v-checkbox>
      <v-checkbox v-model="product.aax" label="AAX"></v-checkbox>
      <v-checkbox v-model="product.lv2" label="LV2"></v-checkbox>
    </div>
    <div id="operatingSystems">
      <v-checkbox v-model="product.windows" label="Windows"></v-checkbox>
      <v-checkbox v-model="product.mac" label="MacOS"></v-checkbox>
      <v-checkbox v-model="product.linux" label="Linux"></v-checkbox>
    </div>

    <div id="other">
      <v-checkbox v-model="product.isFreemium" label="Is Freemium"></v-checkbox>
      <v-checkbox v-model="product.isEnabled" label="Is Enabled"></v-checkbox>
    </div>
    <v-btn class="primary mb-5" large @click="saveButtonClick()">Save</v-btn>
    <br>

    <span class="title">Image Upload</span>
    <br>
    <div>
      <input
        type="file"
        multiple
        @change="filesChange($event.target.name, $event.target.files)"
        accept="*"
        class="input-file"
      >
    </div>
    <div v-for="upload in product.imageURLs" :key="upload.name">
      <img :src="upload.url">
      <v-text-field v-model="upload.order" type="number"></v-text-field>
    </div>
    <br>
    <br>
    <span class="title">Audio Upload</span>
    <br>
    <input
      type="file"
      multiple
      @change="downloadFilesSelected($event.target.name, $event.target.files)"
      accept="*"
      class="input-file"
    >

    <div v-for="upload in product.audioURLs" :key="upload.name" class="mb-5">
      <v-audio-player :name="upload.name" :url="upload.url" class="my-1"></v-audio-player>
      <v-text-field v-model="upload.order" type="number"></v-text-field>
    </div>

    <span class="title">Zip Upload</span>
    <br>
    <span class="body-1">{{product.downloadURL.name}}</span>
    <br>
    <span class="body-1">{{product.downloadURL.url}}</span>
    <br>
    <input
      type="file"
      @change="zipFileSelected($event.target.name, $event.target.files)"
      accept="*"
      class="input-file"
    >

  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Product } from '../models/product';
import { database, storage } from 'firebase/app';
import md5 from 'md5';
import AudioPlayerVue from './AudioPlayer.vue';
import { Upload } from '../models/product';
import { VueEditor } from 'vue2-editor';
import { StorageService } from '../services/storage';

export default Vue.extend({
  data() {
    return {
      html: '' as string,
      productKey: '' as string,
      audioFiles: [] as Upload[],
      bundleId: '',
      product: {
        name: '',
        full_description: '',
        videoPreview: '',
        imageURLs: [],
        downloadURL: '',
        audioURLs: [],
        order: 0,
        category: '',
        bundleIds: []
      } as Product,
      possibleCategories: [
        "Plugin",
        "Samples",
        "Bundle"
      ]
    };
  },
  props: {},
  methods: {
    track() {
      //this.$ga.page('/');
    },
    saveButtonClick() {
      this.productKey === '' || this.productKey === undefined
        ? this.createProduct()
        : this.updateProduct();
    },
    addToBundleIds() {
      if(this.product.bundleIds === undefined)
        this.product.bundleIds = []
      this.product.bundleIds.push(this.bundleId);
      console.log(this.product.bundleIds);
    },
    removeBundleId(bundleId: string) {
      const index = this.product.bundleIds.findIndex(x => x === bundleId);
      this.product.bundleIds.splice(index, 1);
    },
    createProduct() {
      console.log('creating new product');
      const hash = md5(this.product.name);
      console.log('hash = ' + hash);
      const ref = database().ref(`/products/${hash}`);
      ref.set(this.product);
      ref.child('uid').set(hash);
    },
    updateProduct() {
      console.log('updating existing product');
      if (this.productKey !== '') {
        database()
          .ref(`/products/${this.productKey}`)
          .set(this.product);
      }
    },
    downloadFilesSelected(name: string, files: File[]) {
      Array.from(files).forEach((file) => {
        const storageRef = storage().ref(
          `/audio/${this.productKey}/${file.name}`,
        );
        storageRef.put(file).then((x) => {
          storageRef.getDownloadURL().then((url) => {
            const upload: Upload = { name: file.name, url, order: 0 };
            database()
              .ref(`/products/${this.productKey}/audioURLs`)
              .push(upload);
          });
        });
      });
    },
    filesChange(name: string, files: File[]) {
      Array.from(files).forEach((file) => {
        const storageRef = storage().ref(
          `products/${this.productKey}/${file.name}`,
        );
        storageRef.put(file).then((x) => {
          storageRef.getDownloadURL().then((url) => {
            const upload: Upload = { name: file.name, url, order: 0 };
            database()
              .ref(`/products/${this.productKey}/imageURLs`)
              .push(upload);
          });
        });
      });
    },
    zipFileSelected(name: string, files: File[]) {
      const file = files[0];
      const storageRef = storage().ref(
        `products/${this.productKey}/${file.name}`,
      );
      storageRef.put(file).then((x) => {
        storageRef.getDownloadURL().then((url) => {
          const upload: Upload = { name: file.name, url, order: 0 };
          database()
            .ref(`/products/${this.productKey}/downloadURL`)
            .set(upload);
        });
      });
    },
    handleImageAdded(file: any, Editor: any, cursorLocation: any, resetUploader: any) {
      StorageService.upload(file, 'post_images').then((url) => {
        Editor.insertEmbed(cursorLocation, 'image', url);
      });
    },
  },
  created() {
    const name = this.$route.params.name;
    if (name !== undefined) {
      database()
        .ref('/products')
        .on('value', (snapshot) => {
          const products = snapshot.val();
          Object.keys(products).forEach((key) => {
            const p = products[key];
            if (p.name === name) {
              this.product = p as Product;
              this.productKey = key;
            }
          });
        });
    }
  },
  components: {
    VueEditor,
    'v-audio-player': AudioPlayerVue,
  },
});
</script>

<style scoped>
img {
  max-height: 30vh;
}
</style>

