<template>
  <v-container grid-list-md >
    <v-layout flex wrap>
      <v-flex lg2 sm0></v-flex>
      <v-flex lg8 class="content  pa-3">
        <v-layout flex wrap class="text-center">
          <v-flex xs12 lg12>
            <div class="display-3 mt-4">Support & FAQ</div>
            <br>
            <v-divider></v-divider>
          </v-flex>
          <v-flex xs12 lg12>
            <div class="display-2 mt-4">Contact</div>
            <br>
          </v-flex>
        </v-layout>
        <v-layout flex wrap align-center justify-center class="mx-5">
          <v-flex xs2 lg1 text-lg-center text-left>
            <a href="mailto:support@cutthroughrecordings.com" target="_top">
              <v-icon class="mt-2 image-link" size="45">email</v-icon>
            </a>
          </v-flex>
          <v-flex xs10 lg3 text-lg-left text-left class="body-1" >
            <span style="font-size: 2vh;">support@cutthroughrecordings.com</span>
          </v-flex>
          <v-flex xs12 lg4 text-lg-center text-left>
            <a href="https://discord.gg/zrcJmDz" target="_blank">
              <img src="../assets/discord.svg" class="image-link">
            </a>  
          </v-flex>
          <v-flex xs2 lg1 text-center text-left>
            <a href="https://github.com/ctrecordings" target="_blank">
              <div class="white--text">
                <img src="../assets/githublite.png" class="image-link">
              </div>
            </a>
          </v-flex>
          <v-flex xs10 lg3 text-lg-left text-left class="body-1">
            <span style="font-size: 2vh;">Github</span>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout flex wrap text-lg-center text-left>
          <v-flex xs12 class="display-2 mt-4">
            Documentation
          </v-flex>
          <v-flex xs12 text-left>
            <div class="ma-3" style="font-size: 2vh;">
              Plugin documentation is a statically hosted site at <a href="https://docs.cutthroughrecordings.com/" target="_blank">docs.cutthroughrecordings.com</a>
              <br>
              <span class="font-italic">Note that the plug-in documentation website is still a work in progress.  This website will be updated and improved over time.</span>

            </div>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout flex wrap text-lg-center text-left>
          <v-flex xs12 class="display-2 mt-4">
            FAQ
          </v-flex>
          <v-flex xs12 text-left>
            <div class="title">What hosts are supported?</div>
            <div class="mb-4 mt-3" style="font-size: 2vh;">
              Currently you can find a list of known compatible hosts <a href="https://github.com/AuburnSounds/Dplug/wiki/Host-Support" target="_blank">here</a>. If your host is not in this list, I welcome you to try the demo version of the plug-in first.
              If your host is not supported, we will work with you to get it supported!
            </div>
            <div class="title">What copy protection is used?</div>
            <div class="mb-4" style="font-size: 2vh;">
              None!  For the moment, there is no copyright protection used in our plug-ins.  We plan to add a license key system at some point in the near future.
            </div>
            <div class="title">Which operating systems are supported?</div>
            <div class="mb-4" style="font-size: 2vh;">
              Currently we support Windows 7, Windows 10, macOS 10.12 and up, and Linux with Glibc v2.1 or higher.
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex lg2 sm0></v-flex>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  
})
</script>

<style scoped>
.image-link {
  max-height: 7vh;
}
</style>
