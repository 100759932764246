<template>
  <v-layout flex wrap class="elevation-5 py-5 backgroundDark">
     
    <!-- <v-flex xs0 sm0 md3 lg3></v-flex>
    <v-flex id="TopLeft" class="text-center" xs12 sm12 md3 lg3>
      <v-btn>Test</v-btn>
    </v-flex>
    <v-flex id="TopRight" class="text-center" xs12 sm12 md3 lg3>

      <v-btn>Test</v-btn>
    </v-flex>
    <v-flex xs0 sm0 md3 lg3></v-flex>
    
    <v-flex xs0 sm0 md3 lg3></v-flex>
    <v-flex id="Bottom" class="text-center" xs12 sm12 md6 lg6>

      <v-btn>Test</v-btn>
    </v-flex>
    <v-flex xs0 sm0 md3 lg3></v-flex> -->
    
    <v-flex xs0 sm0 md4 lg4></v-flex>
    <v-flex xs12 sm12 md4 lg4 class="px-3">
      <div style="color: white;">
        <div class="mb-5">
          <div>
            <span class="title">Blog</span>
          </div>
          <hr class="white my-2">
          <div class="body-1">
            <a
              href="http://www.modernmetalproduction.com"
              target="_blank"
              style="word-wrap: break-word; color: white;"
            >Modern Metal Production</a>
          </div>
        </div>
        <div class="mt-3 mb-2">
          <div>
            <span class="title">Support / Contact</span>
          </div>
          <hr class="white my-2">
          <v-layout flex wrap align-center>
            <v-flex xs2>
              <a href="mailto:support@cutthroughrecordings.com" target="_blank">
                <v-icon class="mt-2 image-link" size="45">email</v-icon>
              </a>
            </v-flex>
            <v-flex xs2>
              <a href="https://docs.cutthroughrecordings.com" target="_blank">
                <v-icon class="mt-2 image-link" size="45">list_alt</v-icon>
              </a>
            </v-flex>
            <v-flex xs2>
              <a href="https://github.com/ctrecordings" target="_blank">
                <div class=" mt-2 white--text">
                  <img src="../assets/github.png" class="image-link">
                </div>
              </a>
            </v-flex>
            <v-flex xs4>
              <a href="https://discord.gg/zrcJmDz" target="_blank">
                <img src="../assets/Discord.png" class="image-link mt-2">
              </a>  
            </v-flex>
          </v-layout>
        </div>

        <div class="mt-3 mb-5 ml-0 pl-0">
          <hr class="white my-2">
          <div id="mc_embed_signup" class="backgroundDark">
            <form action="https://modernmetalproduction.us16.list-manage.com/subscribe/post?u=d6914e8ceeb9a8943566e4fac&amp;id=7e340f9281" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate ml-0 pl-1" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
                <label for="mce-EMAIL">Subscribe to our mailing list</label>
                <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required>
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_d6914e8ceeb9a8943566e4fac_7e340f9281" tabindex="-1" value=""></div>
                <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
              </div>
            </form>
          </div>

        </div>

        <v-dialog max-width="400" v-model="emailListModal">
          <v-card>
            <v-card-title style="color: white;">Thanks for signing up!</v-card-title>
            <v-card-text>
              <p>You will now be notified of any great new deals or offers as well as any big news from us. We promise not to spam you or give your email address to anyone else. Have a wonderful day!</p>
              <v-btn @click="emailListModal = false" class="primary">Okay</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-flex>
    <v-flex xs0 sm0 md4 lg4></v-flex>
  </v-layout>
</template>

<script lang="ts">
import Vue from 'vue';
import PayPal from 'vue-paypal-checkout';
import { database } from 'firebase/app';

export default Vue.extend({
  data() {
    return {
      thankYouModal: false as boolean,
      emailListModal: false as boolean,
      emailAddress: '' as string,
      paypal: {
        style: {
          label: 'checkout',
          size: 'small',    // small | medium | large | responsive
          shape: 'rect',         // pill | rect
          color: 'blue',         // gold | blue | silver | black
        },
        credentials: {
          sandbox: process.env.VUE_APP_PAYPAL_KEY,
          production: process.env.VUE_APP_PAYPAL_KEY,
        },
      },
    };
  },
  name: 'v-ctr-footer',
  methods: {
    paypalCompleted() {
      this.thankYouModal = true;
    },
    subscribeToNewsletter() {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.emailAddress !== '' && re.test(String(this.emailAddress).toLowerCase())) {
        database().ref('/emailList').push(this.emailAddress).then((x) => {
          this.emailAddress = '';
          this.emailListModal = true;
        });
      }
    },

  },
  computed: {
    getEnv: {
      get() {
        const mode = process.env.NODE_ENV;
        return mode === 'production' ? 'production' : 'sandbox';
      },
    },
  },
  created() {
  },
  components: {
    PayPal,
  },
});
</script>

<style scoped>
.image-link {
  max-height: 5vh;
}
</style>

